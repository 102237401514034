//这个页面调接口获取数据，然后把他遍历成路由配置，最后把他添加到路由

import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import pages from './routes/pages'
import others from './routes/others'
import dash from './routes/dash'
import meun from './routes/meun'

const routes = [
  { path: '/', redirect: { name: 'dash' } },
  ...dash,
  ...pages,
  ...others,
  ...meun,
]

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
