import request from "../utils/request";


export default{
    //获取验证码
    getcaptcha() {
    return request({
            url:"/captcha",
            method:"GET"
        })
    },
    //用户登录
    login(data) {
        return request({
            url:"/api/user/login",
            method:"POST",
            data,
        })
    },
    //医生列表
    doctorlist() {
        return request({
            url:"/api/user/doctorlist",
            method:"GET",
        })
    },
    //医生列表
    list() {
        return request({
            url:"/api/user/list",
            method:"GET",
        })
    },
    changedoctorlist() {
        return request({
            url:"/api/user/changedoctorlist",
            method:"GET",
        })
    },
    //分页
    userlist(data) {
        return request({
            url:"/api/user/pagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //创建用户
    createuser(data) {
        return request({
            url:"/api/user/createsysuser",
            method:"POST",
            data,
        })
    },
    //删除门诊
    deleteuser(data) {
        return request({
            url:"/api/user/delete",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //编辑用户
    edituser(data) {
        return request({
            url:'/api/user/edit',
            method:"POST",
            data
        })
    },
    //编辑用户
    changepassword(data) {
        return request({
            url:'/api/user/changepassword',
            method:"POST",
            data
        })
    },
}


//获取当前用户的菜单   就用这个接口
export function tokenverify(){
    return request({
        url:"/api/user/verify",
        method:"GET",
    })
}
