import request from "../utils/request";


export default{
    //获取菜单
    getrouterlist() {
        return request({
            url:"/api/getrouterapi/list",
            method:"GET",
        })
    },
    //创建菜单
    addnewrouter(data) {
        return request({
            url:"/api/getrouterapi/addrouter",
            method:"POST",
            data,
        })
    },
    //获取单个菜单
    getonerouter(id) {
        return request({
            url:'/api/getrouterapi/getonerouter/',
            method:"GET",
            params:{
                id:id,
            }
        })
    },
    //删除菜单
    getdeleterouter(data) {
        return request({
            url:"/api/getrouterapi/delete",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //编辑菜单
    geteditonerouter(data) {
        return request({
            url:"/api/getrouterapi/editonerouter",
            method:"POST",
            data,
        })
    },
    //分页
    getpagination(data) {
        return request({
            url:"/api/getrouterapi/pagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    allroutes() {
        return request({
            url:"/api/getrouterapi/allroutes",
            method:"GET",
        })
    },
}

//获取当前用户的菜单   就用这个接口
export function allroutes(data){
    return request({
        url:"/api/getrouterapi/allroutes",
        method:"POST",
        data,
    })
}

