import request from "../utils/request";


export default{
    //获取当天就诊列表
    visitlist(data) {
        return request({
            url:"/api/meetorderapi/visitlist",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //医生接诊
    confirmvisit(data) {
        return request({
            url:"/api/meetorderapi/confirmvisit",
            method:"POST",
            data,
        })
    },
    //医生取消会诊
    cancellvisit(id) {
        return request({
            url:"/api/meetorderapi/cancellvisit",
            method:"POST",
            data:{
                id,
            },
        })
    },
    //保存病历
    saveprescription(data) {
        return request({
            url:"/api/meetorderapi/saveprescription",
            method:"POST",
            data,
        })
    },
    //修改病历
    editsavemedicalrecordswitch(data) {
        return request({
            url:"/api/meetorderapi/editsavemedicalrecordswitch",
            method:"POST",
            data,
        })
    },
    //叫号大屏
    callstation() {
        return request({
            url:"/api/meetorderapi/callstation",
            method:"GET"
        })
    },
    //获取就诊历史
    getvisithistory(id) {
        return request({
            url:"/api/meetorderapi/visithistory",
            method:"GET",
            params:{
                id,
            }
        })
    },
    //获取就诊历史
    getidmeetorder(id) {
        return request({
            url:"/api/meetorderapi/getidmeetorder",
            method:"GET",
            params:{
                id,
            }
        })
    },
    //转诊
    submitchangedoctor(savedata,doctor,orderinfo) {
        return request({
            url:"/api/meetorderapi/submitchangedoctor",
            method:"POST",
            data: {
                data: savedata,
                doctor: doctor,
                orderinfo: orderinfo
            }
        })
    },
    //再次叫号播报
    recallnum(message) {
        return request({
            url:"/api/meetorderapi/recallnum",
            method:"POST",
            data: {
                message,
            }
        })
    },
    //获取当天收费列表
    chargevisitlist(data) {
        return request({
            url:"/api/meetorderapi/chargevisitlist",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //治疗理疗执行操作
    submittedexecutenum(data) {
        return request({
            url:"/api/meetorderapi/submittedexecutenum",
            method:"POST",
            data:{
                data
            }
        })
    },
    //根据时间范围获取已收款的订单
    timeframeorder(days) {
        return request({
            url:"/api/meetorderapi/timeframeorder",
            method:"GET",
            params:{
                days,
            }
        })
    },
    //根据时间范围获取已收款的订单
    yeasframeorder(data) {
        return request({
            url:"/api/meetorderapi/yeasframeorder",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //根据时间范围获取已收款的订单
    yeasistruetotal() {
        return request({
            url:"/api/meetorderapi/yeasistruetotal",
            method:"GET",
        })
    },
    //当天营业数据
    dailydata() {
        return request({
            url:"/api/meetorderapi/dailydata",
            method:"GET",
        })
    },
    //当前五天内用户的增长
    wxusertotal() {
        return request({
            url:"/api/meetorderapi/wxusertotal",
            method:"GET",
        })
    },
}
