import request from "../utils/request";


export default{
    //预约订单分页分页
    getpagination(data) {
        return request({
            url:"/api/meetorderapi/pagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //签到确认
    confirmsignin(data) {
        return request({
            url:"/api/meetorderapi/confirmsignin",
            method:"POST",
            data,
        })
    },
    //个人订单列表
    personorderlist(data) {
        return request({
            url:"/api/meetorderapi/personorderlist",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //6天前诊金统计
    dailysexdaytotal() {
        return request({
            url:"/api/meetorderapi/dailytotal",
            method:"GET",
        })
    },
    //根据手机号或者患者姓名查找订单
    searchphoneororder(data) {
        return request({
            url:"/api/meetorderapi/searchphoneororder",
            method:"GET",
            params:{
                searchpatientvalue:data,
            }
        })
    },
    //收费
    submittedpaystatement(data) {
        return request({
            url:"/api/meetorderapi/submittedpaystatement",
            method:"POST",
            data,
        })
    },
    //获取订单记录
    getorderrecordbyid(id) {
        return request({
            url:"/api/meetorderapi/getorderrecordbyid",
            method:"GET",
            params:{
                id,
            }
        })
    },
    //退货
    refundpaystatement(data) {
        return request({
            url:"/api/meetorderapi/refundpaystatement",
            method:"POST",
            data,
        })
    },
    //获取当天就诊时段
    gettodayscheduling(data) {
        return request({
            url:"/api/meetorderapi/gettodayscheduling",
            method:"get",
            params:{
                data,
            }
        })
    },
    //搜索患者用户
    searchwxuserinfamily(searchtext) {
        return request({
            url:"/api/meetorderapi/searchwxuserinfamily",
            method:"get",
            params:{
                searchtext,
            }
        })
    },
    createneworder(data) {
        return request({
            url:"/api/meetorderapi/createneworder",
            method:"post",
            data
        })
    },
}
