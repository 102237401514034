import { allroutes } from '../../api/menu'
import router from '@/router/index.js'

export default {
    namespaced: true,
    state: {
        menulist:[],
        name:'nide',
        menus:[]
    },
    getters: {
    },
    mutations: {
        setRoutes(state,data){
            state.menulist = data
        },
        setMenu(state,data){
            state.menus = data;
        },
        setname(state,name){
            state.name = name;
        }
    },
    actions: {
        async fetchRoutes({ commit }) {
            try{
                const response = await allroutes()
                const menulist = response.data
                commit('setname',menulist.code)
                console.log(menulist.code)
            }catch(error){
                console.log(error)
            }
        },
        fetchname({ commit }) {
            const name = "改变"
            console.log("改变")
            commit('setname',name)
        },
        getMenu({ commit }){
            return new Promise(resolve=>{
                // 获取当前的菜单
                allroutes()
                .then(res=>{
                    if(res.data.code == 200 ){
                        // 更新store中的menus
                        commit("setMenu",res.data.data);
                        // 代表承诺promise执行完毕
                        resolve(admin);
                    }
                })
            })
        }
    }
}