import request from "../utils/request";


export default{
    //wx用户列表分页
    getpagination(data) {
        return request({
            url:"/api/wxuser/userapi/pagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //手机首字母等查找用户列表分页
    searchphoneoruser(data) {
        return request({
            url:"/api/wxuser/userapi/searchphoneoruser",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //根据用户id查找
    userdetail(id) {
        return request({
            url:"/api/wxuser/userapi/userdetail",
            method:"GET",
            params:{
                id,
            }
        })
    },
    //根据用户openid查找
    openiduserdetail(openid) {
        return request({
            url:"/api/wxuser/userapi/openiduserdetail",
            method:"GET",
            params:{
                openid,
            }
        })
    },
    editapplet(data) {
        return request({
            url:"/api/appletapi/edit",
            method:"POST",
            data
        })
    }
}
