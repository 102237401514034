import request from "../utils/request";

//消息通知
export default{
    getnoticelist(role){
        return request({
            url:"/api/noticeapi/getnoticelist",
            method:"GET",
            params:{
                role
            },
        })
    },
    //单条信息已读
    isreader(data) {
        return request({
            url:"/api/noticeapi/isreader",
            method:"POST",
            data,
        })
    },
    //全部信息已读
    allisreader(data) {
        return request({
            url:"/api/noticeapi/allisreader",
            method:"POST",
            data,
        })
    },
}