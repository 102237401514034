import io from 'socket.io-client';
import Vue from 'vue'
import store from '@/store/index'
import Toast from 'vue-toastification'
import Moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'
const datafromdate = {
    date: Moment(new Date()).format("YYYY-MM-DD"),
};


function speak(sentence) { 
    // 生成需要语音朗读的内容 
    const utterance = new SpeechSynthesisUtterance(sentence) 
    // 由浏览器发起语音朗读的请求 
    window.speechSynthesis.speak(utterance) 
} 

function room() { 
    const userData = localStorage.getItem('userData');
    if(userData){
        const {role,fullName} = JSON.parse(localStorage.getItem('userData'))
        let roomname = ""
        if(role === "doctor"){
            roomname = fullName
        }else{
            roomname = "public"
        }
        return roomname
    }else{
        return ""
    }
} 

const socket = io({ 
    autoConnect: false, // 禁止自动连接
    reconnection: true,
    reconnectionAttempts:Infinity,
    reconnectionDelay:1000,
    reconnectionDelayMax:5000,
    timeout:20000,
    transports:['websocket','polling'],
});

socket.on('connect', () => {
    console.log('>>>>>>> 和服务器已建立连接')
    socket.emit('joinroom', room());
})
socket.on('disconnect', () => {
    console.log('断开链接')
})
socket.on('msgorder', (data) => {
    console.log('有个新的预约:', data);
    store.dispatch('notice/fetchNotice')
    Vue.$toast({
        component: ToastificationContent,
        position: 'top-left',
        props: {
          title: '有个新的预约',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: `${data.meetperson}预约了${data.doctor}`,
        },
    });
    speak("有个新的预约")
});
socket.on('messageFromVisit', () => {
    console.log('messageFromVisit');
    store.dispatch('notice/fetchVisit',datafromdate)
});
socket.on('updataischargevisit', () => {
    store.dispatch('notice/fetchchargeVisit',datafromdate)
});



export default socket