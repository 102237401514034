import request from "../utils/request";

export default{
    //创建病史词条
    createhistory(data) {
        return request({
            url:"/api/templatesapi/createhistory",
            method:"POST",
            data,
        })
    },
    //病史词条分页
    medicalhistorylist(data) {
        return request({
            url:"/api/templatesapi/medicalhistorylist",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //编辑病史词条
    editmedicalhistory(data) {
        return request({
            url:"/api/templatesapi/editmedicalhistory",
            method:"POST",
            data
        })
    },
    //删除病史词条
    deletemedicalhistory(data) {
        return request({
            url:"/api/templatesapi/deletemedicalhistory",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //创建诊断词条
    creatediagnosis(data) {
        return request({
            url:"/api/templatesapi/creatediagnosis",
            method:"POST",
            data,
        })
    },
    //诊断词条分页
    diagnosislist(data) {
        return request({
            url:"/api/templatesapi/diagnosislist",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //编辑诊断词条
    editdiagnosis(data) {
        return request({
            url:"/api/templatesapi/editdiagnosis",
            method:"POST",
            data
        })
    },
    //删除诊断词条
    deletediagnosis(data) {
        return request({
            url:"/api/templatesapi/deletediagnosis",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //创建症状词条
    createsymptom(data) {
        return request({
            url:"/api/templatesapi/createsymptom",
            method:"POST",
            data,
        })
    },
    //症状词条分页
    symptomlist(data) {
        return request({
            url:"/api/templatesapi/symptomlist",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //编辑症状词条
    editsymptom(data) {
        return request({
            url:"/api/templatesapi/editsymptom",
            method:"POST",
            data
        })
    },
    //删除症状词条
    deletesymptom(data) {
        return request({
            url:"/api/templatesapi/deletesymptom",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //创建医嘱词条
    createadvice(data) {
        return request({
            url:"/api/templatesapi/createadvice",
            method:"POST",
            data,
        })
    },
    //医嘱词条分页
    advicelist(data) {
        return request({
            url:"/api/templatesapi/advicelist",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //编辑医嘱词条
    editadvice(data) {
        return request({
            url:"/api/templatesapi/editadvice",
            method:"POST",
            data
        })
    },
    //删除医嘱词条
    deleteadvice(data) {
        return request({
            url:"/api/templatesapi/deleteadvice",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //所有词条
    alltemplates() {
        return request({
            url:"/api/templatesapi/alltemplates",
            method:"GET",
        })
    },
    //创建处方模板
    createzhprescription(data) {
        return request({
            url:"/api/templatesapi/createzhprescription",
            method:"POST",
            data,
        })
    },
    //处方模板分页
    zhprescriptionlist(data) {
        return request({
            url:"/api/templatesapi/zhprescriptionlist",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //编辑处方模板
    editzhprescription(data) {
        return request({
            url:"/api/templatesapi/editzhprescription",
            method:"POST",
            data
        })
    },
    //删除处方模板
    deletezhprescription(data) {
        return request({
            url:"/api/templatesapi/deletezhprescription",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //搜索处方模板
    searchzhprescription(data) {
        return request({
            url:"/api/templatesapi/searchzhprescription",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //创建综合词条
    createComprehensive(data) {
        return request({
            url:"/api/templatesapi/createComprehensive",
            method:"POST",
            data,
        })
    },
    //综合词条分页
    Comprehensivelist(data) {
        return request({
            url:"/api/templatesapi/Comprehensivelist",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //编辑综合词条
    editComprehensive(data) {
        return request({
            url:"/api/templatesapi/editComprehensive",
            method:"POST",
            data
        })
    },
    //删除综合词条
    deleteComprehensive(data) {
        return request({
            url:"/api/templatesapi/deleteComprehensive",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    SearchComprehensiveType(data){
        return request({
            url:"/api/templatesapi/SearchComprehensiveType",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //综合词条
    allComprehensive(){
        return request({
            url:"/api/templatesapi/allComprehensive",
            method:"GET"
        })
    },
    //创建栏目
    categories(data) {
        return request({
            url:"/api/templatesapi/categories",
            method:"POST",
            data,
        })
    },
    //顶级栏目菜单
    topcategories(){
        return request({
            url:"/api/templatesapi/topcategories",
            method:"GET"
        })
    },
    //删除栏目
    deletecategories(data) {
        return request({
            url:"/api/templatesapi/deletecategories",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //所有栏目
    allcategories(data) {
        return request({
            url:"/api/templatesapi/allcategories",
            method:"GET",
            params:{
                data,
            }
        })
    },
    editcategories(data) {
        return request({
            url:"/api/templatesapi/editcategories",
            method:"POST",
            data,
        })
    },
    getpermissionscategories() {
        return request({
            url:"/api/templatesapi/getpermissionscategories",
            method:"GET",
        })
    },
    //保存中药处方模版
    saveMedicineTemplate(data) {
        return request({
            url:"/api/templatesapi/saveMedicineTemplate",
            method:"POST",
            data,
        })
    },
    //所有中药处方模版
    allMedicineTemplates(data) {
        return request({
            url:"/api/templatesapi/allMedicineTemplates",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //删除中药处方模版
    deleteMedicineTemplates(data) {
        return request({
            url:"/api/templatesapi/deleteMedicineTemplates",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //更新中药处方模版
    updataMedicineTemplate(data) {
        return request({
            url:"/api/templatesapi/updataMedicineTemplate",
            method:"POST",
            data,
        })
    },
    //根据ID获取子栏目
    getchildrenCategory(data) {
        return request({
            url:"/api/templatesapi/getchildrenCategory",
            method:"get",
            params:{
                data,
            }
        })
    },
    getCategoryidmedicineTemplate(data) {
        return request({
            url:"/api/templatesapi/getCategoryidmedicineTemplate",
            method:"get",
            params:{
                data,
            }
        })
    },
    searchmedicineTemplate(data) {
        return request({
            url:"/api/templatesapi/searchmedicineTemplate",
            method:"GET",
            params:{
                data,
            }
        })
    },
}