import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import menu from './menu'
import routes from './routes'
import notice from './notice'
import systeminfo from './systeminfo'
import doctor from './doctor'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    menu,
    routes,
    notice,
    systeminfo,
    doctor
  },
  strict: process.env.DEV,
})
