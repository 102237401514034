import doctor from '@/api/sysuser'

export default {
    namespaced: true,
    state: {
        doctorlist: [],
    },
    getters: {
    },
    mutations: {
      SET_DOCTORLIST(state, data) {
          state.doctorlist = data
      },
    },
    actions: {
      async fetchDoctorlist({commit}) {
        const response = await doctor.doctorlist()
        const list = response.data.data
        let extractedData = ''
        if(list.length > 0){
            extractedData = list.map(item => ({ label: item.name, value: item._id }));
            commit('SET_DOCTORLIST',extractedData)
        }else{
            extractedData = []
            commit('SET_DOCTORLIST',extractedData)
        }
        return extractedData
      },
    }
}