import request from "../utils/request";


export default{
    //创建时段
    createtimeslot(data) {
        return request({
            url:"/api/timeslot/createtimeslot",
            method:"POST",
            data,
        })
    },
    //门诊列表
    list() {
        return request({
            url:"/api/timeslot/list",
            method:"GET",
        })
    },
    //获取分页
    getpagination(data) {
        return request({
            url:"/api/timeslot/pagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //编辑门诊
    timeslotedit(data) {
        return request({
            url:'/api/timeslot/edit',
            method:"POST",
            data
        })
    },
    //删除门诊
    deletetimeslot(data) {
        return request({
            url:"/api/timeslot/delete",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
}
