import sysusers from '@/api/sysuser'
import menus from '@/api/menu'
import roleids from '@/api/roleid'
import permission from '@/api/permission'
import upload from '@/api/upload'
import applet from '@/api/applet'
import clinic from '@/api/clinic'
import timeslot from '@/api/timeslot'
import article from '@/api/article'
import wxuser from '@/api/wxuser'
import notice from '@/api/notice'
import meetorder from '@/api/meetorder'
import inventory from '@/api/inventory'
import visit from '@/api/visit'
import templates from '@/api/templates'
import banner from '@/api/banner'
import transation from '@/api/transation'
import goods from '@/api/goods'
import drugdata from '@/api/drugdata'
export default {
    sysusers,
    menus,
    roleids,
    permission,
    upload,
    applet,
    clinic,
    timeslot,
    article,
    wxuser,
    notice,
    meetorder,
    inventory,
    visit,
    templates,
    banner,
    transation,
    goods,
    drugdata
};

