import router from "@/router";
import store from '@/store'
import NProgress from 'nprogress'
import { tokenverify } from '@/api/sysuser'
import'nprogress/nprogress.css'


router.beforeEach( async (to, form, next) => {
  NProgress.start()
  const token = localStorage.getItem('token')
  if(to.name !== 'auth-login' && !token) next({name:"auth-login"});
  if(to.name === 'auth-login' && token) next({name:"dash"});
  if(token && store.state.routes.routes.length === 0){
    tokenverify().then(res=>{
      const role = res.data.data.role
      store.dispatch("routes/fetchMenus",role).then(()=>{
        store.dispatch('notice/fetchNotice')
        store.dispatch('systeminfo/fetchSysteminfo')
        const filterRoutes = store.state.routes.routes
        filterRoutes.push({ path: '*', redirect: 'error-404', hidden: true })
        router.addRoutes(filterRoutes)
        next()
      })
    }).catch(err=>{console.log(err)})
  }
  next()
})


// router.beforeEach( async (to, form, next) => {
//     NProgress.start()
//     const token = localStorage.getItem('token')
//     if(to.name !== 'auth-login' && !token) next({name:"auth-login"});
//     if(to.name === 'auth-login' && token) next({name:"dash"});
//     if(token && store.state.routes.routes.length === 0){
//       const role = ''
//       store.dispatch("routes/fetchMenus",role).then(()=>{
//         const filterRoutes = store.state.routes.routes
//         filterRoutes.push({ path: '*', redirect: 'error-404', hidden: true })
//         router.addRoutes(filterRoutes)
//         next()
//       })
//     }
//     next()
// })


router.afterEach((to)=>{
    document.title = to.name
    NProgress.done()
})


export default router

