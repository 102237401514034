import Vue from 'vue'
import axios from 'axios'
import api from './api/index'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
//import api from './api/index'
import router from '@/permission'
import store from './store'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(ElementUI);
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.prototype.$api = api
axios.defaults.baseURL = '/api'
//axios.defaults.withCredentials = true

//Vue.prototype.$axios = axios
//api.axios.baseURL = '/api'
//api.axios.withCredentials = true

Vue.config.productionTip = false
//console.log(router.options.routes)

Vue.directive('debounce', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 2000)//我这里设置的是2000毫秒也就是2秒
    })
  }
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
