import request from "../utils/request";


export default{
    //logo上传
    createapplet(data) {
        return request({
            url:"/api/appletapi/createapplet",
            method:"POST",
            data,
        })
    },
    list() {
        return request({
            url:"/api/appletapi/list",
            method:"GET",
        })
    },
    editapplet(data) {
        return request({
            url:"/api/appletapi/edit",
            method:"POST",
            data
        })
    },
    saveeditor(data) {
        return request({
            url:"/api/appletapi/saveeditor",
            method:"POST",
            data
        })
    }
}
