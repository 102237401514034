import request from "../utils/request";


export default{
    //分页
    allgoods(data) {
        return request({
            url:"/api/goodsapi/allgoods",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //分页
    creategoods(data) {
        return request({
            url:"/api/goodsapi/creategoods",
            method:"POST",
            data,
        })
    },
    //所有入库草稿
    allWarehousingdrugdata(data) {
        return request({
            url:"/api/goodsapi/allWarehousingdrugdata",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //入库草稿
    Warehousingdrugdata(data) {
        return request({
            url:"/api/goodsapi/Warehousingdrugdata",
            method:"POST",
            data,
        })
    },
    //删除单条入库草稿
    deleteWarehousingdrugdata(data) {
        return request({
            url:"/api/goodsapi/deleteWarehousingdrugdata",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //批量入库
    operateWarehousing(data) {
        return request({
            url:"/api/goodsapi/operateWarehousing",
            method:"PUT",
            data,
        })
    },
    //入库历史
    Warehousingdrugdatahistory(data) {
        return request({
            url:"/api/goodsapi/Warehousingdrugdatahistory",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //删除入库历史
    deleteWarehousingdrugdatahistory(data) {
        return request({
            url:"/api/goodsapi/deleteWarehousingdrugdatahistory",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //搜索入库历史
    searchWarehousingStatementhistory(data) {
        return request({
            url:"/api/goodsapi/searchWarehousingStatementhistory",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //西药库存
    allwesternmedicine(data) {
        return request({
            url:"/api/goodsapi/allwesternmedicine",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //搜索西药
    searchwesternmedicine(data) {
        return request({
            url:"/api/goodsapi/searchwesternmedicine",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //搜索过期天数物品
    searchExpirationDatemedicine(data) {
        return request({
            url:"/api/goodsapi/searchExpirationDatemedicine",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //根据入库时间范围查询物品
    searchstarttoendmedicine(data) {
        return request({
            url:"/api/goodsapi/searchstarttoendmedicine",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //中药库存
    allchinesemedicine(data) {
        return request({
            url:"/api/goodsapi/allchinesemedicine",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //搜索中药
    searchchinesemedicine(data) {
        return request({
            url:"/api/goodsapi/searchchinesemedicine",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //根据入库时间范围查询物品
    searchstarttoendchinesemedicine(data) {
        return request({
            url:"/api/goodsapi/searchstarttoendchinesemedicine",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //所有诊疗项目
    alltreatment(data) {
        return request({
            url:"/api/goodsapi/alltreatment",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //添加诊疗项目
    addtreatment(data) {
        return request({
            url:"/api/goodsapi/addtreatment",
            method:"POST",
            data,
        })
    },
    //删除诊疗项目
    deletetreatment(data) {
        return request({
            url:"/api/goodsapi/deletetreatment",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //搜索诊疗项目
    searchtreatment(data) {
        return request({
            url:"/api/goodsapi/searchtreatment",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //搜索诊疗项目与物品
    searchtreatmentandmedicine(data) {
        return request({
            url:"/api/goodsapi/searchtreatmentandmedicine",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //搜索中西成药
    searchvisitwesternmedicine(data) {
        return request({
            url:"/api/goodsapi/searchvisitwesternmedicine",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //搜索中药
    searchvisitchinesemedicine(data) {
        return request({
            url:"/api/goodsapi/searchvisitchinesemedicine",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //修改药品
    editgood(data) {
        return request({
            url:"/api/goodsapi/editgood",
            method:"POST",
            data,
        })
    },
    //删除药品
    deletegoods(data) {
        return request({
            url:"/api/goodsapi/deletegoods",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
}