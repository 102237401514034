import request from "../utils/request";


export default{
    //获取角色
    getroleidlist() {
        return request({
            url:"/api/getroleid/list",
            method:"GET",
        })
    },
    //创建角色
    addroleid(data) {
        return request({
            url:"/api/getrouterapi/addrouter",
            method:"POST",
            data,
        })
    },
    //创建角色(现用)
    createroleid(data) {
        return request({
            url:"/api/getroleid/createroleid",
            method:"POST",
            data,
        })
    },
    //分页
    roleidlist(data) {
        return request({
            url:"/api/getroleid/pagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //修改
    roleedit(data) {
        return request({
            url:"/api/getroleid/edit",
            method:"POST",
            data
        })
    },
    //删除角色
    deleterole(data) {
        return request({
            url:"/api/getroleid/delete",
            method:"DELETE",
            params:{
                data,
            }
        })
    },

}

