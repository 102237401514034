import request from "../utils/request";


export default{
    //图片上传
    bannercover(data) {
        return request({
            url:"/api/bannerapi/bannercover",
            method:"POST",
            data,
            headers: {
                "Content-Type": "multipart/form-data", // 设置请求头的 Content-Type
            },
        })
    },
    //创建广告
    createbanner(data) {
        return request({
            url:"/api/bannerapi/createbanner",
            method:"POST",
            data,
        })
    },
    //分页
    getpagination(data) {
        return request({
            url:"/api/bannerapi/pagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //编辑广告
    editbanner(data) {
        return request({
            url:"/api/bannerapi/edit",
            method:"POST",
            data
        })
    },
    //删除广告
    deletebanner(data) {
        return request({
            url:"/api/bannerapi/delete",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //获取综合信息屏广告列表
    otherbanner() {
        return request({
            url:"/api/bannerapi/otherbanner",
            method:"GET"
        })
    },
    //获取综合信息屏广告列表
    indexbanner() {
        return request({
            url:"/api/bannerapi/indexbanner",
            method:"GET"
        })
    },
}
