import request from "../utils/request";


export default{
    //封面图片上传
    createarticle(data) {
        return request({
            url:"/api/articleapi/createarticle",
            method:"POST",
            data,
        })
    },
    //分页
    getpagination(data) {
        return request({
            url:"/api/articleapi/pagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    list() {
        return request({
            url:"/api/appletapi/list",
            method:"GET",
        })
    },
    editarticle(data) {
        return request({
            url:"/api/articleapi/edit",
            method:"POST",
            data
        })
    },
    //删除文章
    deletearticle(data) {
        return request({
            url:"/api/articleapi/delete",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
}
