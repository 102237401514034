import { allroutes } from '../../api/menu'
import { generateRoutes , generatemenus } from '@/router/routes/ui-elements/genreateroutes'
import socket from '@/utils/socket'
const dudingmenus = [
    {
        title: '工作台',
        icon: 'HomeIcon',
        tagVariant: 'light-warning',
        route: 'dash',
    },
]

export default {
    namespaced: true,
    state: {
        menus: [], // 存储菜单数据
        routes: [], // 存储路由配置
        role:""
    },
    getters: {
    },
    mutations: {
        SET_MENUS(state, data) {
            state.menus = data
        },
        
        SET_ROUTES(state, data) {
            state.routes = data
        },

        SET_ROLE(state, data) {
            state.role = data
        }
    },
    actions: {
      async fetchMenus({commit},role) {
        commit('SET_ROLE',role)
        socket.connect()
        const response = await allroutes({role:role})
        const rolelist = generateRoutes(response.data.data)
        commit('SET_ROUTES',rolelist)
        const menulist = generatemenus(response.data.data)
        commit('SET_MENUS',dudingmenus.concat(menulist))
      }
    }
}