// axios
import axios from 'axios'
import router from '../router'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
axios.defaults.withCredentials = true
const request = axios.create({
  baseURL: 'https://yiliaoapi.80000e.com',
  //baseURL: '/api',
  timeout: 80000,
  withCredentials:true,
})

request.interceptors.request.use(
  (config) =>{
    const token = localStorage.getItem('token')
    if(token) config.headers.Authorization =`Bearer ${token}`
    return config
  },
  (error) => Promise.reject(error)
)

request.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) =>{
    console.log(error.error)
    if (error.code === 'ECONNABORTED' || error.message === 'Network Error' || error.message.includes('timeout')) {
      console.log(error.code)
      return Vue.$toast({
        component: ToastificationContent,
        props: {
          title: "网络超时",
          icon: 'CoffeeIcon',
          variant: 'success',
          text: "请刷新网页或检查网络连接情况",
        },
      });
    }
    const {status} = error.response
    if(status === 401){
      router.push({ name: 'auth-login' });
      localStorage.clear()
    } 
    if(status == 500){
      Vue.$toast({
        component: ToastificationContent,
        position: 'top-left',
        props: {
          title: "网络问题",
          icon: 'CoffeeIcon',
          variant: 'success',
          text: "链接失败",
        },
      });
    }
    return Promise.reject(error)
  }
)




export default request
