import applet from '@/api/applet'

export default {
    namespaced: true,
    state: {
        systeminfo: {}
    },
    getters: {
    },
    mutations: {
      SET_SYSTEMINFO(state, data) {
          state.systeminfo = data
      },
    },
    actions: {
      async fetchSysteminfo({commit}) {
        const response = await applet.list()
        commit('SET_SYSTEMINFO',response.data.data.info)
      },
    }
}