import request from "../utils/request";


export default{
    //创建门诊
    createapplet(data) {
        return request({
            url:"/api/clinic/createclinic",
            method:"POST",
            data,
        })
    },
    //门诊列表
    list() {
        return request({
            url:"/api/clinic/list",
            method:"GET",
        })
    },
    //分页
    getpagination(data) {
        return request({
            url:"/api/clinic/pagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //编辑门诊
    editclinic(data) {
        return request({
            url:'/api/clinic/edit',
            method:"POST",
            data
        })
    },
    //删除门诊
    deleteclinic(data) {
        return request({
            url:"/api/clinic/delete",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
}
