import request from "../utils/request";


export default{
    //分页
    allgoods(data) {
        return request({
            url:"/api/drugdataapi/allgoods",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //分页
    allDrugdata(data) {
        return request({
            url:"/api/drugdataapi/allDrugdata",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //搜索
    searchallDrugdata(data) {
        return request({
            url:"/api/drugdataapi/searchallDrugdata",
            method:"GET",
            params:{
                data,
            }
        })
    },
}