import request from "../utils/request";

export default{
    //添加供应商
    createsupplier(data) {
        return request({
            url:"/api/inventoryapi/createsupplier",
            method:"POST",
            data,
        })
    },
    //供应商分页/搜索
    getpagination(data) {
        return request({
            url:"/api/inventoryapi/pagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //更新供应商
    editsupplier(data) {
        return request({
            url:'/api/inventoryapi/editsupplier',
            method:"POST",
            data
        })
    },
    //删除供应商
    deletesupplier(data) {
        return request({
            url:"/api/inventoryapi/deletesupplier",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //搜索供应商
    searchsupplier(data) {
        return request({
            url:"/api/inventoryapi/searchsupplier",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //所有供应商
    allsupplier() {
        return request({
            url:"/api/inventoryapi/allsupplier",
            method:"GET",
        })
    },
    //西药分页+搜索
    westdrugpagination(data) {
        return request({
            url:"/api/inventoryapi/westdrugpagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //西药入库
    createwestdrug(data) {
        return request({
            url:"/api/inventoryapi/createwestdrug",
            method:"POST",
            data,
        })
    },
    //编辑西药
    editwestdrug(data) {
        return request({
            url:"/api/inventoryapi/editwestdrug",
            method:"POST",
            data,
        })
    },
    //删除西药
    deletewestdrug(data) {
        return request({
            url:"/api/inventoryapi/deletewestdrug",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //全部西药
    allwestdrug() {
        return request({
            url:"/api/inventoryapi/allwestdrug",
            method:"GET",
        })
    },
    //批量保存excel
    saveexcel(data) {
        return request({
            url:"/api/inventoryapi/saveexcel",
            method:"POST",
            data
        })
    },
    //中药入库
    createzhcndrug(data) {
        return request({
            url:"/api/inventoryapi/createzhcndrug",
            method:"POST",
            data,
        })
    },
    //中药分页+搜索
    zhcndrugpagination(data) {
        return request({
            url:"/api/inventoryapi/zhcndrugpagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //编辑中药
    editzhcndrug(data) {
        return request({
            url:"/api/inventoryapi/editzhcndrug",
            method:"POST",
            data,
        })
    },
    //删除中药
    deletezhcndrug(data) {
        return request({
            url:"/api/inventoryapi/deletezhcndrug",
            method:"DELETE",
            params:{
                data,
            }
        })
    },
    //全部中药
    allzhcndrug() {
        return request({
            url:"/api/inventoryapi/allzhcndrug",
            method:"GET",
        })
    },
    //批量保存中药excel
    savezhcnexcel(data) {
        return request({
            url:"/api/inventoryapi/savezhcnexcel",
            method:"POST",
            data
        })
    },
    //诊疗项目入库
    createconsulting(data) {
        return request({
            url:"/api/inventoryapi/createconsulting",
            method:"POST",
            data,
        })
    },
    //诊疗项目+搜索
    consultingpagination(data) {
        return request({
            url:"/api/inventoryapi/consultingpagination",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //编辑诊疗项目
    editconsulting(data) {
        return request({
            url:"/api/inventoryapi/editconsulting",
            method:"POST",
            data,
        })
    },
    //删除诊疗项目
    deleteconsulting(data) {
        return request({
            url:"/api/inventoryapi/deleteconsulting",
            method:"DELETE",
            params:{
                data,
            }
        })
    }
}
