export function generateRoutes(menuList) {
  const routes = [];

  menuList.forEach((item) => {
    if (item.children && item.children.length > 0) {
      // 递归处理子菜单
      const childrenRoutes = generateRoutes(item.children);

      // 将子菜单生成的路由合并到主菜单中
      routes.push(...childrenRoutes);
    } else {
      // 生成当前菜单项的路由对象
      const route = {
        path: item.path,
        name: item.name,
        meta: item.meta || {},
        component: () => import(`@/views${item.path}.vue`),
      };

      // 添加到主路由中
      routes.push(route);
    }
  });

  return routes;
}
//渲染菜单
// export function generatemenus(menuList) {
//   const routes = menuList.map((item) => {
//     const route = {
//       // 路由路径
//       title: item.title,
//       // 路由名称
//       route: item.name,
//       // 路由元数据，用于控制访问权限等
//       icon: item.icon,
//     };

//     // 递归处理子菜单
//     if (item.children && item.children.length > 0) {
//       route.children = generatemenus(item.children);
//     }
//     return route;
//   });
//   return routes;
// }

export function generatemenus(menuList) {
  const routes = menuList.map((item) => {
    if (item.isshow === false) {
      return null; // 如果 isshow 为 false，则返回 null
    }
    const route = {
      // 路由路径
      title: item.title,
      // 路由名称
      route: item.name,
      // 路由元数据，用于控制访问权限等
      icon: item.icon,
    };
    // 递归处理子菜单
    if (item.children && item.children.length > 0) {
      route.children = generatemenus(item.children);
    }
    return route;
  }).filter(item => item !== null); // 过滤掉所有为 null 的菜单项
  return routes;
}




export function generateCALEMDAR(data) {
  const lists = data.map((item) => {
    const list = {
      // 路由路径
      label: item.clinicname,
      // 路由名称
      color: danger,
    };

    // 递归处理子菜单
    if (item.children && item.children.length > 0) {
      list.children = generatemenus(item.children);
    }
    return list;
  });
  return lists;
}