import request from "../utils/request";


export default{
    //预约流水分页
    getpagination(data) {
        return request({
            url:"/api/wxpayapi/transactionlist",
            method:"GET",
            params:{
                data,
            }
        })
    },
     //收款退款流水分页
     paymenttransationlist(data) {
        return request({
            url:"/api/wxpayapi/paymenttransationlist",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //根据预约订单状态搜索
    searchstatus(data) {
        return request({
            url:"/api/wxpayapi/searchstatus",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //根据收款退款流水订单状态搜索
    paymentsearchstatus(data) {
        return request({
            url:"/api/wxpayapi/paymentsearchstatus",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //根据预约类型搜索
    searchtype(data) {
        return request({
            url:"/api/wxpayapi/searchtype",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //根据收款退款类型搜索
    paymentsearchtype(data) {
        return request({
            url:"/api/wxpayapi/paymentsearchtype",
            method:"GET",
            params:{
                data,
            }
        })
    },
    //退款申请
    orderrefund(data) {
        return request({
            url:"/api/wxpayapi/orderrefund",
            method:"POST",
            params:{
                data,
            }
        })
    },
}
