import request from "../utils/request";

export default{
   permission() {
    // 使用 request 模块发送网络请求并返回响应数据
    return request({
      url: '/api/getrouterapi/allroutes',
      method: 'GET'
    });
  }
};