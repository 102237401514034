import request from "../utils/request";


export default{
    //logo上传
    logo(data) {
        return request({
            url:"/api/appletapi/logo",
            method:"POST",
            data,
            headers: {
                "Content-Type": "multipart/form-data", // 设置请求头的 Content-Type
            },
        })
    },
    //文章封面上传
    articlecover(data) {
        return request({
            url:"/api/articleapi/articlecover",
            method:"POST",
            data,
            headers: {
                "Content-Type": "multipart/form-data", // 设置请求头的 Content-Type
            },
        })
    },
    //文章内容图片上传
    contentimg(data) {
        return request({
            url:"/api/articleapi/contentimg",
            method:"POST",
            data,
            headers: {
                "Content-Type": "multipart/form-data", // 设置请求头的 Content-Type
            },
        })
    },
    //医生头像上传
    doctoravatar(data) {
        return request({
            url:"/api/user/avatar",
            method:"POST",
            data,
            headers: {
                "Content-Type": "multipart/form-data", // 设置请求头的 Content-Type
            },
        })
    }
}
