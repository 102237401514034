import notice from '../../api/notice'
import visit from '@/api/visit'
// function sortData(data) {
//   const sortedData = data.filter(item => item.signin && item.statusvisit === "waiting");
//   const visitingData = data.filter(item => item.signin && item.statusvisit === "visiting");
//   sortedData.sort((a, b) => {
//     const aTime = new Date(`1970-01-01T${a.products.firsttime}:00Z`).getTime();
//     const bTime = new Date(`1970-01-01T${b.products.firsttime}:00Z`).getTime();
//     if (aTime !== bTime) {
//       return aTime - bTime;
//     }
//     return a.updatedAt - b.updatedAt;
//   });
//   return visitingData.concat(sortedData, data.filter(item => !item.signin || (item.statusvisit !== "waiting" && item.statusvisit !== "visiting")));
// }

// function sortData(data) {
//   const sortedData = data.filter(item => item.signin);
//   sortedData.sort((a, b) => {
//     const aTime = new Date(`1970-01-01T${a.products.firsttime}:00Z`).getTime();
//     const bTime = new Date(`1970-01-01T${b.products.firsttime}:00Z`).getTime();
//     if (aTime !== bTime) {
//       return aTime - bTime;
//     }
//     return a.updatedAt - b.updatedAt;
//   });
//   return sortedData
// }

function sortData(data) {
  const filteredData = data.filter(item => item.signin !== false);
  
  const waitingData = filteredData.filter(item => item.statusvisit === 'waiting' || item.statusvisit === 'visiting');
  const finishData = filteredData.filter(item => item.statusvisit === 'finish');

  waitingData.sort((a, b) => {
    const aTime = new Date(`1970-01-01T${a.products.firsttime}:00Z`).getTime();
    const bTime = new Date(`1970-01-01T${b.products.firsttime}:00Z`).getTime();
    if (aTime !== bTime) {
      return aTime - bTime;
    }
    return a.updatedAt - b.updatedAt;
  });

  finishData.sort((a, b) => a.updatedAt - b.updatedAt);

  return [...waitingData, ...finishData];
}

function chargesortData(data) {
  const filteredData = data.filter(item => item.medicalrecord && (item.medicalrecord.consulting.length > 0 || item.medicalrecord.westrecipe.length > 0 || item.medicalrecord.zhcnrecipe.length > 0));
  
  const waitingData = filteredData.filter(item => item.ischarge === false);
  const finishData = filteredData.filter(item => item.ischarge === true);

  waitingData.sort((a, b) => {
    const aTime = new Date(`1970-01-01T${a.products.firsttime}:00Z`).getTime();
    const bTime = new Date(`1970-01-01T${b.products.firsttime}:00Z`).getTime();
    if (aTime !== bTime) {
      return aTime - bTime;
    }
    return a.updatedAt - b.updatedAt;
  });

  finishData.sort((a, b) => a.updatedAt - b.updatedAt);

  return [...waitingData, ...finishData];
}


export default {
    namespaced: true,
    state: {
        noticelist: [],
        visitlist: [],
        ChargeVisit: [],
    },
    getters: {
    },
    mutations: {
      SET_NOTICELIST(state, data) {
          state.noticelist = data
      },
      SET_VISITLIST(state, data) {
        state.visitlist = data
      },
      SET_CHARGEVISIT(state, data) {
        state.ChargeVisit = data
      },
      updateMeetperson(state, payload) {
        const { id, value, key } = payload;
        // console.log('id',id)
        // console.log('value',value)
        // console.log('key',key)
        const meetinfo = state.visitlist.find(item => item._id === id);
        const issun = key.includes('medicalrecord')
        const canshu = key.split('.')[1];
        if (meetinfo) {
          if(issun){
            if(!meetinfo.medicalrecord) {
              meetinfo.medicalrecord = {};
            }
            meetinfo.medicalrecord[canshu] = value;
          }else{
            meetinfo[key] = value;
            //console.log(meetinfo[key])
          }
        }
        console.log(meetinfo)
      },
    },
    actions: {
      async fetchNotice({commit}) {
        const response = await notice.getnoticelist()
        commit('SET_NOTICELIST',response.data.noticelist)
        return response.data.noticelist
      },
      async fetchVisit({commit},databody) {
        const response = await visit.visitlist(databody)
        const setvisit = await sortData(response.data.data)
        commit('SET_VISITLIST',setvisit)
        return response.data.data
      },
      async fetchchargeVisit({commit},data) {
        const response = await visit.chargevisitlist(data)
        const chargesetvisit = await chargesortData(response.data.data)
        commit('SET_CHARGEVISIT',chargesetvisit)
        return response.data.data
      }
    }
}